.chat-box {
  position: fixed;
  top: 0;
  right: -400px; /* Initially, the chat box is hidden to the right */
  width: 400px;
  height: 100%;
  background-color: #fff;
  transition: right 0.3s ease-in-out;
}

.chat-box.open {
  left: 0; /* When open, the chat box slides in from the right */
  width: "200px";
}

.chat-header {
  background-color: #333;
  color: #fff;
  padding: 10px;
  text-align: center;
}

.chat-content {
  padding: 10px;
}

button {
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}
