.main {
    display: flex;
    width: 100vw;

}

.sidebar {
    height: 100vh;
    align-items: center;

}

.navdashboard {
    display: flex;
    width: 100%;
    align-items: center;
    transition: "all 0.5s";
    border-radius: 7px;
    background-color: RGB(217 240 240);
    color: #279d85;


}
.navdashboard .iconStyle{
    color: #279d85;
}



.iconStyle {
    color: #222;
    font-weight: 300;
    line-height: 1.334em;
    /* font-size: 0.875rem; */
    font-size: 2rem;
    min-width: 40px;
    /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */

}



span:hover {
    color: #279d85;
}

.btn:hover {
    background-color: rgb(217 240 240);
}


