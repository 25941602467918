

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  /* font-size: 0.875rem; */
  /* line-height:1.334em; */
  /* font-weight: 500; */
  
}





body{
  margin: 0;
  font-family: 'Roboto', sans-serif;

  background-color: 	#f2f3f4;
}






:root {
  --color-bg: #1f1f38;
  --color-bg-variant: #2c2c6c;
  --color-primary: #4db5ff;
  --color-primary-variant: rgb(77, 181, 255, 0.4);
  --color-white: #fff;
  --color-light: rgb(255, 255, 255, 0.6);
  --transition: all 400ms ease;
  --container-width-lg: 75%;
  --container-width-md: 86%;
  --container-width-sm: 90%;
  --text-color:"#344767"
}

html {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  display: none;
}
