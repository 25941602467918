.chatImgPosition{
  position: fixed;
  bottom: 10px;
  right: 80px;
  background-color: #D9F0F0;
  padding: 10px 15px;
  border-radius: 14px;
  cursor: pointer;
 z-index: 20;
}


.chatImgPosition img {
  cursor: pointer;
  transition: transform 0.3s ease-in-out; /* Add a transition for the transform property */
}

.chatImgPosition img:hover {
  transform: scale(1.8) translateY(-5px); /* Apply a scale transform and move 5px up when the image is hovered */
}




.chatbot {
  z-index: 12;
    position: fixed;
    bottom: 10px;
    right: 80px;
    background-color: #D9F0F0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: transform 0.6s;
  }
  
  .chatbot.closed {
    transform: translateX(100%);
  }
  
  .chatbot-content {
    display: flex;
    gap: 8px;
    margin-top: 16px;
  }